import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const CartIcon = () => {
    const [cartItemCount, setCartItemCount] = useState(0);

    useEffect(() => {
        // Retrieve cart items from local storage
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];

        console.log('Stored Cart:', storedCart);

        // Calculate the total number of items in the cart
        const totalCount = storedCart.reduce((acc, item) => {
            console.log('Item:', item);
            return acc + parseInt(item.quantity, 10);
        }, 0);

        console.log('Total Count:', totalCount);

        setCartItemCount(totalCount);
    }, []);

    return (
        <div className='cart_icon'>
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className="badge ">{cartItemCount}</span>
        </div>
    );
};

export default CartIcon;
