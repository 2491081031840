import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ShoppingCart = () => {
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        // Retrieve cart items from local storage
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(storedCart);
    }, []);

    const removeItem = (productId) => {
        // Remove the item from the cart based on the productId
        const updatedCart = cartItems.filter((item) => item.productId !== productId);
        setCartItems(updatedCart);

        // Update local storage with the modified cart
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const updateQuantity = (productId, newQuantity) => {
        // Update the quantity of the specified product in the cart
        const updatedCart = cartItems.map((item) =>
            item.productId === productId ? { ...item, quantity: newQuantity } : item
        );
        setCartItems(updatedCart);

        // Update local storage with the modified cart
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const calculateItemTotal = (item) => {
        return item.quantity * item.price;
    };

    const calculateGrandTotal = () => {
        return cartItems.reduce((total, item) => total + calculateItemTotal(item), 0);
    };

    const formatPrice = (price) => {
        // Use Intl.NumberFormat for better currency formatting
        return new Intl.NumberFormat('ro-RO', {
            style: 'currency',
            currency: 'lei',
        }).format(price);
    };

    const handleCompleteOrder = () => {
        const addToCartParams = [];
        const quantityParams = [];
        const metaParams = [];

        cartItems.forEach((item, index) => {
            addToCartParams.push(`add-to-cart[]=${item.productId}`);
            quantityParams.push(`quantity[]=${item.quantity}`);

            // Verifică dacă item.meta_data este definit înainte de a itera prin el
            if (item.meta_data && Array.isArray(item.meta_data)) {
                item.meta_data.forEach((meta, metaIndex) => {
                    // Asigură-te că key și value sunt definite și nu conțin caractere nepermise în URL
                    const key = encodeURIComponent(meta.key);
                    const value = encodeURIComponent(meta.value);

                    // Construiește corect parametrii pentru meta_data
                    metaParams.push(`meta_data[${index}][${metaIndex}][key]=${key}&meta_data[${index}][${metaIndex}][value]=${value}`);
                });
            }
        });

        const addToCartURL = addToCartParams.join('&');
        const quantityURL = quantityParams.join('&');
        const metaURL = metaParams.join('&');

        const finalURL = `${process.env.REACT_APP_API_URL}/finalizare/?${addToCartURL}&${quantityURL}&${metaURL}&ver=app&addFromApp&remove`;

        window.location.href = finalURL;
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Shopping Cart</h2>
            {cartItems.length > 0 ? (
                <div>
                    {cartItems.map((item) => (
                        <div key={item.productId} className="card mb-3">
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <a href={`/product/?productId=${item.productId}`} className="card-href">
                                        {item.imageUrl && (
                                            <img
                                                src={item.imageUrl}
                                                alt={item.name}
                                                className="img-fluid"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    </a>
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">{item.name}</h5>
                                        {item.meta_data && Array.isArray(item.meta_data) && (
                                            <ul>
                                                {item.meta_data.map((meta, index) => (
                                                    <li key={index}>{`${meta.key}: ${meta.value}`}</li>
                                                ))}
                                            </ul>
                                        )}
                                        <p className="card-text">
                                            <small className="text-muted">Quantity:</small>
                                            <input
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => updateQuantity(item.productId, e.target.value)}
                                                min="1"
                                            />
                                        </p>
                                        <p className="card-text">
                                            <small className="text-muted">Price:</small> {formatPrice(item.price)}
                                        </p>
                                        <p className="card-text">
                                            <small className="text-muted">Total:</small> {formatPrice(calculateItemTotal(item))}
                                        </p>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => removeItem(item.productId)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex justify-content-between mt-4">
                        <div>
                            <strong>Grand Total:</strong>
                        </div>
                        <div>{formatPrice(calculateGrandTotal())}</div>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                        <button
                            className="btn btn-secondary"
                            onClick={() => {
                                window.location.href = '/products';
                            }}
                        >
                            Continue Shopping
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={handleCompleteOrder}
                        >
                            Complete Order
                        </button>
                    </div>
                </div>
            ) : (
                <p>Your cart is empty.</p>
            )}
        </div>
    );
};

export default ShoppingCart;
