import React, { useState, useEffect } from 'react';
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const PuzzleJoc = () => {
    const nivelePuzzle = [
        { piese: 20, imageUrl: 'https://papusica.ro/wp-content/uploads/2022/09/Beauty-Product-and-Cosmetics-Facebook-Cover.png?id=47078' },
        { piese: 20, imageUrl: 'url_nivel_2.jpg' },
        { piese: 20, imageUrl: 'url_nivel_3.jpg' },
        { piese: 20, imageUrl: 'url_nivel_4.jpg' },
        { piese: 20, imageUrl: 'url_nivel_5.jpg' },
    ];

    const [nivelCurent, setNivelCurent] = useState(0);
    const [puzzleRezolvat, setPuzzleRezolvat] = useState(false);

    const handlePuzzleRezolvat = () => {
        if (nivelCurent < nivelePuzzle.length - 1) {
            setNivelCurent((prevNivel) => prevNivel + 1);
            setPuzzleRezolvat(false);
        } else {
            alert('Felicitări! Ai terminat toate nivelele!');
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <h1>Joc Puzzle</h1>
                <p>Nivel: {nivelCurent + 1}</p>

                <PuzzleComponenta
                    imageUrl={nivelePuzzle[nivelCurent].imageUrl}
                    numarPiese={nivelePuzzle[nivelCurent].piese}
                    onPuzzleRezolvat={handlePuzzleRezolvat}
                    setPuzzleRezolvat={setPuzzleRezolvat}
                />

                <button onClick={handlePuzzleRezolvat} disabled={!puzzleRezolvat}>
                    Continuă la nivelul următor
                </button>
            </div>
        </DndProvider>
    );
};

const PuzzleComponenta = ({ imageUrl, numarPiese, onPuzzleRezolvat, setPuzzleRezolvat }) => {
    const [piese, setPiese] = useState([]);

    useEffect(() => {
        const pieseNou = Array.from({ length: numarPiese }, (_, index) => ({ id: index.toString() }));
        setPiese(pieseNou);
    }, [numarPiese]);

    const isPuzzleRezolvat = () => {
        return piese.every((piesa) => piesa.position === piesa.id);
    };

    const [{ isDragging }, dragRef] = useDrag({
        type: 'PIESA', // Specify the type property
        item: { type: 'PIESA' },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: 'PIESA',
        drop: (item, monitor) => {
            const piesaTrascutata = item;
            const piesaDestinatie = monitor.getItem();

            setPiese((prevPiese) => {
                const pieseActualizate = [...prevPiese];
                const indexTrascutata = pieseActualizate.findIndex((p) => p.id === piesaTrascutata.id);
                const indexDestinatie = pieseActualizate.findIndex((p) => p.id === piesaDestinatie.id);

                const temp = pieseActualizate[indexTrascutata];
                pieseActualizate[indexTrascutata] = pieseActualizate[indexDestinatie];
                pieseActualizate[indexDestinatie] = temp;

                return pieseActualizate;
            });
        },
    });

    useEffect(() => {
        if (isPuzzleRezolvat()) {
            onPuzzleRezolvat();
            setPuzzleRezolvat(true);
        }
    }, [piese, onPuzzleRezolvat, setPuzzleRezolvat]);

    return (
        <div ref={dropRef} style={{ display: 'flex', flexWrap: 'wrap' }}>
            {piese.map((piesa) => (
                <PiesaPuzzle key={piesa.id} id={piesa.id} dragRef={dragRef} imageUrl={imageUrl} />
            ))}
        </div>
    );
};

const PiesaPuzzle = ({ id, dragRef, imageUrl }) => {
    return (
        <div
            ref={dragRef}
            style={{
                width: '50px',
                height: '50px',
                margin: '5px',
                background: `url(${imageUrl})`,
                backgroundPosition: `-${(id % 4) * 50}px -${Math.floor(id / 5) * 50}px`,
                backgroundSize: '250px 250px',
                cursor: 'pointer',
                opacity: 0.7,
                border: '1px solid #000',
                borderRadius: '5px',
            }}
        ></div>
    );
};

export default PuzzleJoc;
