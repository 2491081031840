import React, { useState, useEffect} from 'react';
import PopupMenu from './popupmenu';
import CartIcon from './CartIcon';
import { Link } from "react-router-dom";

const Navbar = () => {
  const [primaryColor, setPrimaryColor] = useState('');

  const fetchCategoriesData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/wp-json/options/all`);
      const data = await response.json();


      const elements = document.querySelectorAll('.view_product');
      elements.forEach((element) => {
        element.style.setProperty('background-color', data.primary_color_app, 'important');
        element.style.setProperty('border-color', data.primary_color_app, 'important');
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchCategoriesData();
  }, []);

  useEffect(() => {
    // Apply styles after primaryColor is set
    const elements = document.querySelectorAll('.view_product');

    elements.forEach((element) => {
      element.style.backgroundColor = primaryColor;
      element.style.borderColor = primaryColor;
    });
  }, [primaryColor]);

  return (
    <div className="wrapper wrapper_menu">
      <nav className="nav">
        <NavItem dataColor="#5b37b7">
          <Link to={`/`}>
          <span className="icon-home">
            <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px'>
              <path d='M9,19V13H11L13,13H15V19H18V10.91L12,4.91L6,10.91V19H9M12,2.09L21.91,12H20V21H13V15H11V21H4V12H2.09L12,2.09Z' />
            </svg>
          </span>
          </Link>
        </NavItem>
        

        <NavItem dataColor="#e5a919">
          <PopupMenu />
        </NavItem>

        <NavItem dataColor="#e5a919">
          <Link to={`/cart/`}>
            <CartIcon />
          </Link>
        </NavItem>

        <NavItem dataColor="#c9379d">
          <span className="icon-heart">
            <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px'>
              <path d='M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z' />
            </svg>
          </span>
          <div className="title">Likes</div>
        </NavItem>

        <NavItem dataColor="#e5a919" id="app_search">
          <Link to={`/myaccount/`}>
          <span className="icon-search">
            <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px'>
              <path d='M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z' />
            </svg>
          </span>
          <div className="title">Search</div>
        </Link>
        </NavItem>
      </nav>
    </div>
  );
};

const NavItem = ({ dataColor, children }) => (
  <div className="nav-item" data-color={dataColor}>
    {children}
  </div>
);

export default Navbar;
