import React, { useState, useEffect } from 'react';
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import './SearchComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';

const SearchComponent = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [recentlyClickedLinks, setRecentlyClickedLinks] = useState([]);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (searchTerm.length >= 3) {
                const consumerKey = process.env.REACT_APP_API_CONSUMER_KEY;
                const consumerSecret = process.env.REACT_APP_API_CONSUMER_SECRET;
                const apiURL = process.env.REACT_APP_API_URL;

                const WooCommerce = new WooCommerceRestApi({
                    url: apiURL,
                    consumerKey,
                    consumerSecret,
                    version: 'wc/v3',
                });

                try {
                    const { data } = await WooCommerce.get('products', {
                        search: searchTerm,
                        per_page: 5
                    });

                    setSearchResults(data);
                } catch (error) {
                    console.error(error);
                }
            } else {
                setSearchResults([]);
            }
        };

        fetchSearchResults();
    }, [searchTerm]);

    const closeSearch = () => {
       setIsVisible(!isVisible);
    };

    const handleLinkClick = (link, imageLink, name) => {
        const savedLinks = JSON.parse(localStorage.getItem('recentlyClickedLinks')) || [];
        const updatedLinks = [{ link, imageLink, name }, ...savedLinks].slice(0, 3);
        setRecentlyClickedLinks(updatedLinks);
        localStorage.setItem('recentlyClickedLinks', JSON.stringify(updatedLinks));
    };

    const handleInputFocus = () => {
        const savedLinks = JSON.parse(localStorage.getItem('recentlyClickedLinks')) || [];
        setRecentlyClickedLinks(savedLinks);
        setIsVisible(true);
    };

    const generateProductLink = (productId) => `/product/?productId=${productId}`;

    return (
        <div className="search-container">
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Cauta produse..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={handleInputFocus}
                    className="search-input"
                />
                {searchTerm.length >= 3 && (
                    <div className="close-button" onClick={() => setSearchTerm('')}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                )}

                
                <button className="back-button"  onClick={closeSearch}>
                    {isVisible ? <FontAwesomeIcon icon={faArrowLeft} /> : <FontAwesomeIcon icon={faSearch} />}
                </button>
            </div>
            
            {isVisible && searchTerm.length >= 3 && searchResults.length > 0 && (
                <div className="search-results">
                    <Table striped bordered hover>
                        <tbody>
                            {searchResults.map((product) => (
                                <a className='search_links'  key={product.id} href={generateProductLink(product.id)} onClick={() => handleLinkClick(generateProductLink(product.id), product.images[0].src, product.name)}>
                                    <tr>
                                        <td>
                                            <img src={product.images[0].src} alt={product.name} className="product-image" />
                                        </td>
                                        <td>
                                            {product.name}
                                        </td>
                                    </tr>
                                </a>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
            {isVisible && recentlyClickedLinks.length > 0 && searchResults.length === 0 && (
                <div className="recent-links">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th colspan="2">Cautari anterioare</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentlyClickedLinks.map((linkData, index) => (
                                <tr key={index}>
                                    <a href={linkData.link} className='search_links'>
                                        <td>
                                            <img src={linkData.imageLink} alt={`Product ${index + 1}`} className="product-image" />
                                        </td>
                                        <td>
                                            {linkData.name}
                                        </td>
                                    </a>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

        </div>
    );
};

export default SearchComponent;
