import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
const PopupMenu = () => { 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const [Categories, setCategories] = useState([])
    const fetchCategoriesData = () => {
        fetch("https://papusica.ro/wp-json/wp/v2/menu")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCategories(data);
                console.log(data);

            })
    }
    useEffect(() => {
        fetchCategoriesData()
    }, [])
    
    return (
        <>
            
            <Button onClick={handleShow} id="view_menu">
                <FontAwesomeIcon icon={faBars} />
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Meniu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Categories.length > 0 && (
                        <ul className='appMenuPopup'>
                            {Categories.map(categori => (
                                <li key={categori.id}><a href={categori.url}>{categori.title}</a></li>
                            ))}
                        </ul>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    
                  
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PopupMenu;