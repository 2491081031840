import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { format } from 'date-fns';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [clientName, setClientName] = useState(null);
    const [orders, setOrders] = useState([]);

    const API_CONSUMER_KEY = process.env.REACT_APP_API_CONSUMER_KEY;
    const API_CONSUMER_SECRET = process.env.REACT_APP_API_CONSUMER_SECRET;
    const API_URL = process.env.REACT_APP_API_URL;

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://papusica.ro/wp-json/jwt-auth/v1/token', {
                username,
                password,
            });

            // Verificăm dacă răspunsul conține un token
            if (response.data.token) {
                console.log('Autentificare cu succes:', response.data);
                // Salvăm token-ul și ID-ul clientului în localStorage
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('clientId', response.data.user_id);
                localStorage.setItem('clientName', response.data.user_display_name);

                // Actualizăm starea pentru a reflecta autentificarea cu succes
                setIsLoggedIn(true);
                setClientId(response.data.user_id);
                setClientName(response.data.user_display_name);
            } else {
                setError('Autentificare eșuată. Verificați datele de autentificare.');
            }
        } catch (error) {
            console.error('Eroare la autentificare:', error);
            setError('Eroare la autentificare. Vă rugăm să încercați din nou mai târziu.');
        }
    };

    useEffect(() => {
        // Verificăm în localStorage dacă utilizatorul este autentificat
        const token = localStorage.getItem('token');
        const storedClientId = localStorage.getItem('clientId');
        const storedClientName = localStorage.getItem('clientName');

        if (token && storedClientId) {
            setIsLoggedIn(true);
            setClientId(storedClientId);
            setClientName(storedClientName);
        }

        // Dacă sunteți autentificat, puteți solicita comenzile pentru clientId
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${API_URL}/wp-json/wc/v3/orders?customer=${storedClientId}`, {
                    auth: {
                        username: API_CONSUMER_KEY,
                        password: API_CONSUMER_SECRET,
                    },
                });
                setOrders(response.data);
            } catch (error) {
                console.error('Eroare la obținerea comenzilor:', error);
            }
        };

        if (isLoggedIn && storedClientId) {
            fetchOrders();
        }
    }, [isLoggedIn, clientId, clientName, API_CONSUMER_KEY, API_CONSUMER_SECRET, API_URL]);


    const statusTranslation = {
        pending: 'În așteptare',
        processing: 'În procesare',
        on_hold: 'În așteptare',
        completed: 'Finalizată',
        cancelled: 'Anulată',
        refunded: 'Rambursată',
        failed: 'Eșuată',
    };
    return (
        <div>
            {!isLoggedIn ? (
                <div>
                    <h2>Autentificare</h2>
                    <div>
                        <label>Utilizator:</label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div>
                        <label>Parolă:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button onClick={handleLogin}>Autentificare</button>
                </div>
            ) : (
                <div>
                    <h2>Bun venit, utilizator #{clientName}</h2>
                    <h3>Comenzi:</h3>
                    <div className="card-container">
                        {orders.map((order) => (
                            <Card key={order.id} style={{ margin: '10px' }}>
                                <Card.Body>
                                    <Card.Title>Numar Comandă: {order.id}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Data Comenzii: {format(new Date(order.date_created), 'dd-MM-yyyy HH:mm')}</Card.Subtitle>
                                    <Card.Text>
                                        Total: {order.total}
                                    </Card.Text>
                                    <Card.Text>
                                        {order.line_items.map((item) => (
                                            <img key={item.id} src={item.image.src} alt={item.name} style={{ width: '49%', marginBottom: '5px', display: 'inline-block' }} />
                                        ))}
                                    </Card.Text>
                                    <Card.Text>
                                        Status: {statusTranslation[order.status] || order.status}
                                    </Card.Text>
                                    <Button variant="primary" style={{ width: '100%' }}>Vezi detalii comandă</Button>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
