import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Row, Card, Container, Badge } from 'react-bootstrap';
import { useSearchParams, Link } from "react-router-dom";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import SearchComponent from "../components/searchcomponent";
console.log('ENV: ' + process.env.REACT_APP_API_CONSUMER_KEY);
const api = new WooCommerceRestApi({
    url: process.env.REACT_APP_API_URL,
    consumerKey: process.env.REACT_APP_API_CONSUMER_KEY,
    consumerSecret: process.env.REACT_APP_API_CONSUMER_SECRET,
    version: "wc/v3",
});

const Products = () => {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [searchParams] = useSearchParams();
    let currentPage = searchParams.get("page");
    let totalProducts;
    let totalPages;
    let nextPage;
    let prevPage;
    const [clearCache, setClearCache] = useState(false);
    let currentUrl = window.location.pathname + window.location.search;
    
    let fetchProducts = () => {
        setLoading(true);
        api
            .get("products", {
                per_page: 24,
                status: "publish",
                page: currentPage,
                category: searchParams.get("idCategory"),
                search: searchParams.get("search")
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);

                    localStorage.setItem('allProducts', JSON.stringify(response.data));

                    setLoading(false);
                    setProducts(response.data);
                   
                    totalProducts = response.headers['x-wp-total'];
                    totalPages = totalProducts / 24;
                    
                    nextPage = Number(searchParams.get("page")) + 1;
                    prevPage = Number(searchParams.get("page")) - 1;

                    setTimeout(function () {
                                             
                        const prevPageCat = document.getElementById("prev_page_cat");
                        const nextPageCat = document.getElementById("next_page_cat");

                        if (prevPageCat && nextPageCat) {
                            if (searchParams.get("page") == 1) {
                                prevPageCat.style.opacity = '0.5';
                                prevPageCat.style.pointerEvents = 'none';
                            } else {
                                prevPageCat.style.opacity = '1';
                                prevPageCat.style.pointerEvents = 'auto';
                            }

                            if (searchParams.get("page") == Math.ceil(totalPages)) {
                                nextPageCat.style.opacity = '0.5';
                                nextPageCat.style.pointerEvents = 'none';
                            } else {
                                nextPageCat.style.opacity = '1';
                                nextPageCat.style.pointerEvents = 'auto';
                            }

                            const prevPage = parseInt(searchParams.get("page")) - 1;
                            const nextPage = parseInt(searchParams.get("page")) + 1;

                            prevPageCat.href = `${currentUrl.replace('&page=' + searchParams.get("page"), '')}&page=${prevPage}`;
                            nextPageCat.href = `${currentUrl.replace('&page=' + searchParams.get("page"), '')}&page=${nextPage}`;
                        }

                    }, 1000);

                    if (searchParams.get("search").length > 0) {
                        let pageTitle = document.getElementById('page_title');
                        pageTitle.textContent = "Cautarea: " + searchParams.get("search");
                    }
                }
            })
            .catch((error) => { });
    };

    const memoizedFetchProducts = useMemo(() => fetchProducts, [
        currentPage,
        searchParams.get("idCategory"),
        searchParams.get("search"),
        clearCache,
    ]);

    useEffect(() => {
        memoizedFetchProducts();
        setClearCache(false);
    }, [memoizedFetchProducts, clearCache]);

    const clearCacheHandler = () => {
        setClearCache(true);
    };

    return (
        <div className="App">
            <SearchComponent />
            <Container className='products_list'>
                <Row className="app_header">
                    <Col xs={12}><h1 id="page_title">{searchParams.get("title")}</h1></Col>
                </Row>

                {loading ? (
                    <Row xs={1} md={4} className="g-4 loader-skeleton">
                        {Array.from({ length: 8 }).map((_, index) => (
                            <Col key={index} className="skeleton-card">
                                <div className="skeleton-image"></div>
                                <div className="skeleton-title"></div>
                                <div className="skeleton-text"></div>
                                <div className="skeleton-button"></div>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Row xs={1} md={4} className="g-4">
                        {products.map((product, index) => (
                            <Col key={index}>
                                <Link to={`/product/?productId=${product.id}`} className="card-href">
                                    <Card>
                                        <Card.Img
                                            variant="top"
                                            className="image_list"
                                            src={product.images[0].src}
                                            loading="lazy"
                                            alt={product.name}
                                        />
                                        <Card.Body>
                                            <Card.Title>{product.name}</Card.Title>
                                            {product.on_sale && (
                                                <Badge bg="danger" className="discount-badge">
                                                   {Math.round(((1 - (product.sale_price / product.regular_price)) * 100))}%
                                                </Badge>
                                            )}
                                        </Card.Body>
                                        <Card.Footer>
                                            <Card.Text>
                                                <div dangerouslySetInnerHTML={{ __html: product.price_html }} />
                                            </Card.Text>
                                            <Button variant="primary view_product">Vezi produsul</Button>
                                        </Card.Footer>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                        <div className="center pagination_cat">
                            <div className="pagination">
                                <a to="#" id="prev_page_cat">&laquo; Inapoi</a>
                                <a to="#" id="next_page_cat">Inainte &raquo;</a>
                            </div>
                        </div>
                    </Row>
                )}

                
            </Container>
        </div>
    );
};

export default Products;
