import React from 'react';
import './App.css';
import Navbar from './components/Navbar';

import { BrowserRouter as Router, Routes, Route }from 'react-router-dom';
//import Home from './pages';
import Products from './pages/products';
import ProductDetails from './pages/product';
import ShoppingCart from './pages/cart';
import Home from './pages/home';
import PuzzleJoc from './pages/PuzzleJoc';
import Login from './components/myaccount';
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/puzzle" element={<PuzzleJoc />} />
        <Route path='/products' element={<Products />} />
        <Route path='/product' element={<ProductDetails />} />
        <Route path='/cart' element={<ShoppingCart />} />
        <Route path='/myaccount' element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;