// ProductDetails.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductDetails.css';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCartPlus, faShoppingCart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { Button, Col, Container, Form, Row, Select } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductDetails = () => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [addedToCart, setAddedToCart] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [variations, setVariations] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [searchParams] = useSearchParams();
    
    const [customerName, setCustomerName] = useState('');
    const [customerMessage, setCustomerMessage] = useState('');


    const handleVariantChange = (variantId) => {
        if (product && variations && variations.length > 0) {
            const selectedVariant = variations.find((variant) => variant.id === variantId);

            if (selectedVariant) {
                console.log('Variant selectat:', selectedVariant);
                setSelectedVariant(selectedVariant);

                // Actualizăm prețul în pagină cu prețul variantei selectate
                const priceElement = document.querySelector('.variant-price');
                if (priceElement) {
                    priceElement.innerHTML = `Preț: ${selectedVariant.price || product.regular_price} Lei`;
                }
            }
        }
    };

    const extractTableFromHTML = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const tableElement = doc.querySelector('table');
        return tableElement ? tableElement.outerHTML : null;
    };

    let productId = searchParams.get('productId');
    
    const fetchProductDetails = async () => {
        try {
            // Verificăm dacă detaliile produselor sunt deja în localStorage
            const allProducts = JSON.parse(localStorage.getItem('allProducts'));
            
            if (!allProducts || !allProducts.some(product => product.id === parseInt(productId, 10))) {
                // Dacă detaliile produselor nu sunt disponibile în localStorage, facem un apel API pentru a le obține
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/wp-json/wc/v3/products/${productId}`,
                    {
                        auth: {
                            username: process.env.REACT_APP_API_CONSUMER_KEY,
                            password: process.env.REACT_APP_API_CONSUMER_SECRET,
                        },
                    }
                );
                
                
                // Adăugăm atributul 'price_html' pentru compatibilitate cu restul codului
                const priceHtml = response.data.sale_price
                    ? `<p class="discounted-price">Preț redus: ${response.data.sale_price} Lei</p>
                   <p class="normal-price">Preț normal: <span class="strike-through">${response.data.regular_price}</span> Lei</p>`
                    : `<p class="normal-price">Preț: ${response.data.regular_price} Lei</p>`;

                const unifiedProduct = {
                    ...response.data,
                    price_html: priceHtml,
                };

                setProduct(unifiedProduct);

                // Salvăm detaliile produsului în localStorage
                localStorage.setItem('allProducts', JSON.stringify([unifiedProduct]));

                setLoading(false);
                return;
            }

            // Găsim detaliile produsului cu productId în localStorage
            const productData = allProducts.find((product) => product.id === parseInt(productId, 10));

            if (!productData) {
                console.error('Nu s-au putut găsi detaliile produsului cu productId:', productId);
                setError('Nu s-au putut găsi detaliile produsului.');
                setLoading(false);
                return;
            }

            // Adăugăm atributul 'price_html' pentru compatibilitate cu restul codului
            const priceHtml = productData.sale_price
                ? `<p class="discounted-price">Preț redus: ${productData.sale_price} Lei</p>
               <p class="normal-price">Preț normal: <span class="strike-through">${productData.regular_price}</span> Lei</p>`
                : `<p class="normal-price">Preț: ${productData.regular_price} Lei</p>`;

            const unifiedProduct = {
                ...productData,
                price_html: priceHtml,
            };

            setProduct(unifiedProduct);
            setLoading(false);
        } catch (error) {
            console.error('Eroare la preluarea detaliilor produsului:', error);
            setError('A apărut o eroare la preluarea detaliilor produsului.');
            setLoading(false);
        }
    };


    const fetchRelatedProducts = async () => {
        if (product && product.related_ids && product.related_ids.length > 0) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/wp-json/wc/v3/products`,
                    {
                        params: {
                            include: product.related_ids.join(','),
                        },
                        auth: {
                            username: process.env.REACT_APP_API_CONSUMER_KEY,
                            password: process.env.REACT_APP_API_CONSUMER_SECRET,
                        },
                    }
                );

                setRelatedProducts(response.data);
            } catch (error) {
                console.error('Eroare la preluarea produselor similare:', error);
            }
        }
    };

    const fetchProductVariations = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/wp-json/wc/v3/products/${productId}/variations`,
                {
                    auth: {
                        username: process.env.REACT_APP_API_CONSUMER_KEY,
                        password: process.env.REACT_APP_API_CONSUMER_SECRET,
                    },
                }
            );

            // Adăugăm atributul 'price' în fiecare variantă
            const variationsWithPrices = response.data.map((variant) => {
                const priceHtml = variant.sale_price
                    ? `Preț redus: ${variant.sale_price} Lei
                       Preț normal: ${variant.regular_price} Lei`
                    : `Preț: ${variant.regular_price} Lei`;

                return {
                    ...variant,
                    price: priceHtml,
                };
            });

            setVariations(variationsWithPrices);
        } catch (error) {
            console.error('Eroare la preluarea variantelor produsului:', error);
        }
    };
    
    useEffect(() => {
        fetchProductDetails();
    }, []);

    useEffect(() => {
        if (product) {
            fetchRelatedProducts();
            fetchProductVariations();
        }
    }, [product]);
    

    const addToCart = () => {
        try {
            // Get existing cart items from local storage or initialize an empty array
            const existingCart = JSON.parse(localStorage.getItem('cart')) || [];

            // Check if the product is already in the cart
            const existingItem = existingCart.find((item) => item.productId === product.id);

            if (existingItem) {
                // If the product is already in the cart, update the quantity
                existingItem.quantity += parseInt(selectedQuantity, 10);
            } else {
                // If the product is not in the cart, add a new item
                const newItem = {
                    productId: product.id,
                    name: product.name,
                    quantity: parseInt(selectedQuantity, 10),
                    price: selectedVariant ? selectedVariant.price || product.regular_price : product.regular_price,
                    imageUrl: product.images && product.images.length > 0 ? product.images[0].src : '',
                    meta_data: [
                        { key: 'customer_name', value: customerName },
                        { key: 'customer_message', value: customerMessage },
                    ],
                };
                existingCart.push(newItem);
            }

            // Save the updated cart back to local storage
            localStorage.setItem('cart', JSON.stringify(existingCart));

            // Setează showPopup la true pentru a afișa popup-ul
            setShowPopup(true);

            // Updatează starea pentru a reflecta faptul că produsul a fost adăugat în coș
            setAddedToCart(true);
        } catch (error) {
            console.error('Error adding the product to the cart:', error);
        }
    };



    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
    };

    const CustomPrevArrow = (props) => (
        <button {...props} className="slick-arrow slick-prev">
            <FontAwesomeIcon icon={faArrowLeft} />
        </button>
    );

    const CustomNextArrow = (props) => (
        <button {...props} className="slick-arrow slick-next">
            <FontAwesomeIcon icon={faArrowRight} />
        </button>
    );
    const [thumbnailSlider, setThumbnailSlider] = useState(null);

    const mainSliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        asNavFor: thumbnailSlider,  // Synchronize with the thumbnail slider
    };

    const relatedProductsSliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024, // Adjust the breakpoint as needed
                settings: {
                    slidesToShow: 1, // Number of slides to show on smaller screens
                    slidesToScroll: 1,
                },
            },
            
        ],
        
    };

    const handleCheckout = () => {
        // Poți naviga către pagina coșului sau alte acțiuni relevante
        window.location.href = '/cart';
    };

    const handleContinue = () => {
        // Poți naviga către pagina coșului sau alte acțiuni relevante
        window.location.href = '/products';
    };

    const [selectedQuantity, setSelectedQuantity] = useState(1);
    return (
        <Container className="mt-5">
            {showPopup && (
                <div className="popup">
                    <p>Produsul a fost adăugat în coș!</p>
                    <div className="popup-buttons">
                        <Button variant="secondary" className="continue-shopping" onClick={() => handleContinue()}>
                            <FontAwesomeIcon icon={faShoppingCart} /> Continuă cumpărăturile
                        </Button>
                        <Button variant="success" className="checkout" onClick={() => handleCheckout()}>
                            <FontAwesomeIcon icon={faCheck} /> Finalizează
                        </Button>
                    </div>
                </div>
            )}

            {loading ? (
                <p className="loading">Se încarcă...</p>
            ) : product ? (
                <Container className='product_details'>
                    <Row>
                        <Col md={6}>
                            {product.images && product.images.length > 0 ? (
                                <Slider {...mainSliderSettings}>
                                    {product.images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image.src}
                                            alt={product.name}
                                            className="img-fluid main-image"
                                            onClick={() => handleImageClick(index)}
                                        />
                                    ))}
                                </Slider>
                            ) : (
                                <p>Nu există imagini disponibile</p>
                            )}
                        </Col>
                        <Col md={6}>
                            <h3>{product.name}</h3>
                            <p dangerouslySetInnerHTML={{ __html: product.short_description }} />
                            {selectedVariant ? (
                                <p className="variant-price">
                                    Preț: {selectedVariant.price || product.regular_price} Lei
                                </p>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: product.price_html }} />
                            )}

                            {variations && Array.isArray(variations) && variations.length > 0 ? (
                                <Form.Group controlId="variantDropdown" className="mb-3">
                                    <Form.Label>Selectează o variantă:</Form.Label>
                                    <Form.Select onChange={(e) => handleVariantChange(e.target.value)}>
                                        <option value="">Alege o variantă</option>
                                        {variations.map((variant) => {
                                            const attributes = variant.attributes || [];
                                            const variantName = attributes
                                                .map((attribute) => attribute.name + ': ' + attribute.option)
                                                .join(', ');

                                            return (
                                                <option key={variant.id} value={variant.id}>
                                                    {variantName || 'Nume sau preț indisponibil'} - {variant.price}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            ) : null}

                            <Row className="cantitate">
                                {/* Restul codului */}
                                <Form.Group controlId="quantityInput" className="mb-3">
                                    <Form.Label>Cantitate:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={selectedQuantity}
                                        onChange={(e) => setSelectedQuantity(e.target.value)}
                                        min={1}
                                    />
                                </Form.Group>
                            </Row>

                            {/* Adaugă câmpuri de intrare pentru customerName și customerMessage */}
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="customerNameInput" className="mb-3">
                                        <Form.Label>Nume client:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={customerName}
                                            onChange={(e) => setCustomerName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="customerMessageInput" className="mb-3">
                                        <Form.Label>Mesaj client:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={customerMessage}
                                            onChange={(e) => setCustomerMessage(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button
                                variant="primary"
                                type="button"
                                onClick={addToCart}
                            >
                                <FontAwesomeIcon icon={faCartPlus} />{' '}
                                {addedToCart ? 'Adăugat în coș' : 'Adaugă în coș'}
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <h3>Descriere</h3>
                    {product.description ? (
                        product.description.includes('<table') ? (
                            <div dangerouslySetInnerHTML={{ __html: extractTableFromHTML(product.description) }} />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: product.description }} />
                        )
                    ) : (
                        <p>Nicio descriere disponibilă.</p>
                    )}
                    <hr />
                    <h3>Produse recomandate</h3>
                    <Row>
                        <Slider {...relatedProductsSliderSettings}>
                            {relatedProducts.map((relatedProduct) => (
                                <Col key={relatedProduct.id} md={4}>
                                    <a href={"/product/?productId=" + relatedProduct.id} className="card-href">
                                        <div className="card mb-4">
                                            <img
                                                src={relatedProduct.images[0].src}
                                                alt={relatedProduct.name}
                                                className="card-img-top"
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">{relatedProduct.name}</h5>
                                                <div dangerouslySetInnerHTML={{ __html: relatedProduct.price_html }} />
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            ))}
                        </Slider>
                    </Row>
                </Container>
            ) : (
                <p className="error">{error || 'Niciun produs găsit.'}</p>
            )}
        </Container>
    );
};

export default ProductDetails;
