import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home.css';
import SearchComponent from '../components/searchcomponent';

const Home = () => {
    const [sliderImage, setSliderImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [topProductsData, setTopProductsData] = useState([]);
    const [newProductsData, setNewProductsData] = useState([]);
    const [productOfTheWeek, setProductOfTheWeek] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/wp-json/options/all`);
                const data = await response.json();

                const { slider_image, categories_home, product_categories, product_categories_new, per_page, product_of_the_week } = data;

                setSliderImage(slider_image);
                setCategories(categories_home);

                const wooCommerceApi = new WooCommerceRestApi({
                    url: process.env.REACT_APP_API_URL,
                    consumerKey: process.env.REACT_APP_API_CONSUMER_KEY,
                    consumerSecret: process.env.REACT_APP_API_CONSUMER_SECRET,
                    version: 'wc/v3',
                });

                // Fetch pentru cele mai vândute produse
                const topProductsData = await fetchDataForCategoryOrderByPopularity(wooCommerceApi, product_categories, per_page);
                localStorage.setItem('allProducts', JSON.stringify(topProductsData));
                setTopProductsData(topProductsData);

                // Fetch pentru cele mai noi produse
                const newProductsData = await fetchDataForCategory(wooCommerceApi, product_categories_new, per_page);
                setNewProductsData(newProductsData);

                // Fetch pentru produsul săptămânii
                if (product_of_the_week) {
                    const productOfTheWeekResponse = await wooCommerceApi.get(`products/${product_of_the_week}`);
                    setProductOfTheWeek(productOfTheWeekResponse.data);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchDataForCategoryOrderByPopularity = async (wooCommerceApi, categories, perPage) => {
        const totalPerPage = perPage * categories.length;
        try {
            const response = await wooCommerceApi.get('products', {
                category: categories.join(','),
                per_page: totalPerPage,
                orderby: 'popularity',
                order: 'desc',
                status: 'publish',
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const fetchDataForCategory = async (wooCommerceApi, categories, perPage) => {
        const totalPerPage = perPage * categories.length;
        try {
            const response = await wooCommerceApi.get('products', {
                category: categories.join(','),
                per_page: totalPerPage,
                orderby: 'date',
                order: 'desc',
                status: 'publish',
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    // Configurare Slick Slider pentru categorii
    const categorySliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20%',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // Configurare Slick Slider pentru cele mai vândute produse
    const topProductsSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // Configurare Slick Slider pentru cele mai noi produse
    const newProductsSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    window.onload = () => {
        document.querySelector(".path").style.animation = "swipe-dot 2s 0.5s infinite";
        document.querySelector(".hand-icon").style.animation = "swipe-hand 2s infinite";
    }
    
    return (
        <Container className='products_list homeContainer'>
            <Row><SearchComponent /></Row>
            {sliderImage && (
                <Row>
                    <Col md={12} className='sliderHomePage'>
                        <div className="slider-container">
                            <img src={sliderImage} alt="Slider" className="slider-image" />
                        </div>
                    </Col>
                </Row>
            )}

            {/* Slick Slider pentru categorii */}
            {categories.length > 0 && (
                <Slider {...categorySliderSettings}>
                    {categories.map((category, index) => (
                        <div key={index} className="category-item">
                            <a href={category.url} className="category-link">
                                <img src={category.image} alt={category.title} className="category-image" />
                                <div className="overlay"></div>
                                <h3 className="category-title">{category.title}</h3>
                            </a>
                        </div>
                    ))}
                </Slider>
            )}
           

            <h2 className='title_home'>Produsul saptamanii</h2>
            {/* Card pentru produsul săptămânii */}
            {productOfTheWeek && (
                <Row className="g-4">
                    <Col>
                        <h2>{productOfTheWeek.title}</h2>
                        <Card>
                            <a href={`/product/?productId=${productOfTheWeek.id}`}>
                                {productOfTheWeek.images && productOfTheWeek.images.length > 0 && (
                                    <Card.Img variant="top" src={productOfTheWeek.images[0].src} />
                                )}
                            </a>
                            <Card.Body>
                                <Card.Title>{productOfTheWeek.name}
                                    {productOfTheWeek.on_sale && (
                                        <Badge bg="danger" className="discount-badge">
                                            {Math.round(((1 - (productOfTheWeek.sale_price / productOfTheWeek.regular_price)) * 100))}%
                                        </Badge>
                                    )}
                                </Card.Title>
                            </Card.Body>
                            <Card.Footer>
                                <Card.Text>
                                    <div dangerouslySetInnerHTML={{ __html: productOfTheWeek.price_html }} />
                                </Card.Text>
                                <a href={`/product/?productId=${productOfTheWeek.id}`} className="btn btn-primary view_product">Vezi produsul</a>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            )}

            
            <div class="swipe">
                <div class="path"></div>
                <div class="hand-icon"></div>
            </div>
            <h2 className='title_home'>Cele mai vandute produse</h2>
            {loading ? (
                <Row xs={1} md={4} className="g-4 loader-skeleton">
                    {Array.from({ length: 8 }).map((_, index) => (
                        <Col key={index} className="skeleton-card">
                            <div className="skeleton-image"></div>
                            <div className="skeleton-title"></div>
                            <div className="skeleton-text"></div>
                            <div className="skeleton-button"></div>
                        </Col>
                    ))}
                </Row>
            ) : (
                <Slider {...topProductsSliderSettings}>
                    {topProductsData.map((topProduct, index) => (
                        <Col key={index}>
                            <h2>{topProduct.title}</h2>
                            <Card>
                                <a href={`/product/?productId=${topProduct.id}`}>
                                    {topProduct.images && topProduct.images.length > 0 && (
                                        <Card.Img variant="top" src={topProduct.images[0].src} />
                                    )}
                                </a>
                                <Card.Body>
                                    <Card.Title>{topProduct.name}
                                        {topProduct.on_sale && (
                                            <Badge bg="danger" className="discount-badge">
                                                {Math.round(((1 - (topProduct.sale_price / topProduct.regular_price)) * 100))}%
                                            </Badge>
                                        )}
                                    </Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Text>
                                        <div dangerouslySetInnerHTML={{ __html: topProduct.price_html }} />
                                    </Card.Text>
                                    <a href={`/product/?productId=${topProduct.id}`} className="btn btn-primary view_product">Vezi produsul</a>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Slider>
            )}
         
            <h2 className='title_home'>Cele mai noi produse</h2>
            {loading ? (
                <Row xs={1} md={4} className="g-4 loader-skeleton">
                    {Array.from({ length: 8 }).map((_, index) => (
                        <Col key={index} className="skeleton-card">
                            <div className="skeleton-image"></div>
                            <div className="skeleton-title"></div>
                            <div className="skeleton-text"></div>
                            <div className="skeleton-button"></div>
                        </Col>
                    ))}
                </Row>
            ) : (
                <Slider {...newProductsSliderSettings}>
                    {newProductsData.map((newProduct, index) => (
                        <Col key={index}>
                            <h2>{newProduct.title}</h2>
                            <Card>
                                <a href={`/product/?productId=${newProduct.id}`}>
                                    {newProduct.images && newProduct.images.length > 0 && (
                                        <Card.Img variant="top" src={newProduct.images[0].src} />
                                    )}
                                </a>
                                <Card.Body>
                                    <Card.Title>{newProduct.name}
                                        {newProduct.on_sale && (
                                            <Badge bg="danger" className="discount-badge">
                                                {Math.round(((1 - (newProduct.sale_price / newProduct.regular_price)) * 100))}%
                                            </Badge>
                                        )}
                                    </Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Text>
                                        <div dangerouslySetInnerHTML={{ __html: newProduct.price_html }} />
                                    </Card.Text>
                                    <a href={`/product/?productId=${newProduct.id}`} className="btn btn-primary view_product">Vezi produsul</a>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Slider>
            )}

            

            <a href={`/products?title=Toate%20Produsele`} className="btn btn-primary view_product">Vezi toate produsele</a>
        </Container>
    );
};

export default Home;
